@import "../../styles/_config.scss";

.flagContainer {
  height: 11rem;
  background-color: $light-grey;
  padding: 1rem;
  border-radius: 8px;
  @media (min-width: $media-min-width) {
    height: 21rem;
  }
}