@import "../../styles/_config.scss";

.wrapper {
  @include flex($fd: column, $ai: center, $gap: 3rem);
  position: relative;
  border-radius: 8px;
  padding: 2rem;
  margin: 0;
  text-align: center;
  background: $white;
  box-shadow: -2px 2px 4px 0 $mid-grey;
}

.auto {
  width: 100%;
  @media (min-width: $media-min-width) {
    width: fit-content;
  }
}

.fixed {
  @include size($w: 100%, $maxw: 40rem);
}

.fixedLarge {
  @include size($w: 100%, $maxw: 62rem);
}

.aside {
  width: 100%;
  @media (min-width: $media-min-width) {
    max-width: 20rem;
  }
}

.small {
  gap: 1rem;
  padding: 1rem 2rem;
}
