@import "../../styles/_config.scss";

.background {
  @include flex($jc: center, $ai: center);
  @include size($w: 100%, $h: 100%);
  @include absolute($t: 0);
  background: $white;
  opacity: 0.75;
}

.spinner {
  @include flex($jc: center, $ai: center);
  @include size($w: 100%, $h: 100%);
  @include absolute($t: 0);

  &:after {
    @include pseudo($pos: relative);
    @include size($w: 24px, $h: 24px);
    margin: 0;
    border-radius: 50%;
    border: 4px solid $grey;
    border-color: $grey transparent $grey transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
