@import "../../styles/_config.scss";

.sideDrawer {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background: $white;
  padding: $header-height-mobile 0 1rem;

  @media (min-width: $media-min-width) {
    padding: calc($header-height-web - 1rem) 0 1rem;
  }
}

.enter {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);

  @media (min-width: $media-min-width) {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.enterActive {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);

  opacity: 1;
  -webkit-transition: linear 200ms;
  -o-transition: linear 200ms;
  transition: linear 200ms;

  @media (min-width: $media-min-width) {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.exit {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);

  opacity: 1;

  @media (min-width: $media-min-width) {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.exitActive {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);

  opacity: 0;
  -webkit-transition: linear 200ms;
  -o-transition: linear 200ms;
  transition: linear 200ms;

  @media (min-width: $media-min-width) {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
