@import "../styles/_config.scss";

.cardSection {
  @include flex($jc: center, $fw: wrap);

  height: 100%;

  & > * {
    flex: 0 1 100%;
    @media (min-width: 40rem) {
      flex: 1;
    }
  }
}
