@import "../../../styles/_config.scss";

.navItem {
  font-weight: 700;
  padding: 1rem;
  width: 100%;
  text-align: center;

  a {
    text-decoration: none;
    color: $grey;
    transition: $transition-speed;
    letter-spacing: $letter-spacing;
    &:hover,
    &:active,
    &.active {
      color: var(--color);
    }
  }
}
