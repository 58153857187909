@import "../../styles/_config.scss";

.game {
  @include flex($fd: column, $gap: 2rem);
  margin: 2rem 0 0;

  @media (min-width: $media-min-width) {
    flex-direction: row-reverse;
  }
}

.aside {
  background-color: $white;
  border-radius: 6px;
  width: 100%;
  padding: 1rem;
  margin: 3rem 0 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  @media (min-width: $media-min-width) {
    width: 15rem;
  }

  hr {
    margin: 1rem 0;
  }

  h3 {
    text-align: left;
  }
}
