@import "../../styles/_config.scss";

.scoreboard__list {
  @include flex($fd: column, $gap: 0.5rem);
  padding: 0;
  margin: 1rem 0;
  text-align: left;

  .scoreboard__single {
    @include flex($fd: row, $jc: space-between, $ai: center, $gap: 1rem);

    padding: 0.5em 1em;
    border: 1px solid $mid-grey;
    border-radius: 4px;
    font-weight: 500;
    color: $grey;

    &:first-of-type {
      background-color: var(--color);
      color: $white;
    }
    &:nth-of-type(2),
    &:nth-of-type(3) {
      background-color: var(--pastelColor);
      color: $black;
    }

    p {
      margin: 0;
    }

    .scoreTime {
      @include flex($fd: row, $jc: flex-end, $ai: center, $gap: 2rem);

      .score {
        font-weight: 600;
        font-size: 1.25em;
      }
    }
  }
}
