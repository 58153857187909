@import "../../styles/_config.scss";

.QuizInfo__header {
  @include flex($fd: row, $jc: space-between, $ai: center);

  small {
    color: var(--color);
    font-weight: 500;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
