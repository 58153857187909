@import "../../styles/_config.scss";

.textarea {
  @include size($w: 100%, $minh: 7rem);
  padding: 1rem;
  border: 1px solid $mid-grey;
  border-radius: 8px;
  resize: vertical;

  @media (min-width: $media-min-width) {
    min-width: 20rem;
  }
}

.invalid {
  border: 1px solid $pastel-pink;
}

.invalid__icon {
  @include absolute($t: 1rem, $r: 0);
  color: $dark-pink;
  font-size: 1.25rem;
  margin-right: 1rem;
  stroke-width: 0;
}
