.imageContain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.width100 {
  width: 100%;
}

.error {
  font-weight: 600;
  color: $dark-pink;
}

.input__wrapper {
  @include flex($fd: column);
  font-size: 0.875rem;
  position: relative;

  label {
    font-weight: 700;
    color: $grey;
    margin-bottom: 0.5rem;
  }

  .input__container {
    position: relative;
  }
}
