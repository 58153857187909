@import "../../styles/_config.scss";

.heading {
  @include flex($fd: row, $ai: center);
  width: 100%;

  & > :first-child,
  & > :last-child {
    flex-basis: 50%;
  }
  & > :nth-child(2) {
    flex-basis: 100%;
  }
}

.icon {
  @include flex($jc: flex-start, $ai: center);
  color: $grey;

  svg {
    @include iconBtn();

    &:hover,
    &.active {
      color: var(--color);
    }
  }
}
