/* Colours */
$white: #fff;
$off-white: #faf6f4;
$black: #111;
$grey: #4a4a4a;
$mid-grey: #cccccc;
$light-grey: #f2f2f2;

$pastel-pink: #ffadad;
$dark-pink: #fc6150;

$pastel-yellow: #fdffb6;
$dark-yellow: #ffce00;

$pastel-blue: #90dbf4;
$dark-blue: #009bff;

/* Dimensions */
$header-height-web: 6.8rem;
$header-height-mobile: 4.35rem;
$max-width: 60rem;

/* Spacing */
$letter-spacing: 0.03em;

/* Animation */
$transition-speed: 0.4s;

/* @Media */
$media-min-width: 40rem;

/* THEME COLOURS */
.default {
  --color: #{$grey};
  --pastelColour: #{$mid-grey};
}
.yellow {
  --color: #{$dark-yellow};
  --pastelColor: #{$pastel-yellow};
}
.red {
  --color: #{$dark-pink};
  --pastelColor: #{$pastel-pink};
}
.blue {
  --color: #{$dark-blue};
  --pastelColor: #{$pastel-blue};
}
