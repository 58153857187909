@import "../../styles/_config.scss";

.thumbs {
  @include flex($fd: row, $jc: center, $fw: wrap, $gap: 0.5rem);

  .thumb {
    @include size($maxh: 3rem, $maxw: 5rem);
    padding: 0.5rem;
    background-color: $light-grey;
    border-radius: 2px;
    cursor: pointer;

    img {
      @extend .imageContain;
    }
  }

  .active {
    background-color: var(--color);
  }

  .correct,
  .incorrect {
    position: relative;
    cursor: default;

    &::after {
      @include flex($jc: center, $ai: center);
      @include size($h: 100%, $w: 100%);
      @include absolute($t: 0, $l: 0);
      font-family: "Font Awesome 6 Free";
      background-color: rgba($color: #000, $alpha: 0.5);
      border-radius: 2px;
      font-size: 1.25rem;
      font-weight: 900;
    }
  }

  .correct::after {
    content: "\f058";
    color: var(--pastelColor);
  }

  .incorrect::after {
    content: "\f057";
    color: $pastel-pink;
  }
}
