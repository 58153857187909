@import "styles/_config.scss";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&family=Righteous&display=swap");

/* General Styles */

* {
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}

html,
body,
#root,
.App,
main {
  height: 100%;
}

body {
  margin: 0;
}

.App {
  display: grid;
  grid-template-rows: fit-content(8rem) auto fit-content(8rem);
  grid-template-areas:
    "header"
    "main"
    "footer";
}

footer {
  grid-area: footer;
}

main {
  margin: 0 auto;
  width: 100%;
  padding: 0;
  grid-area: main;
}

/* Headings */

h1,
h2,
h3 {
  margin: 0;
  text-align: center;
}

h1 {
  font-family: "Righteous", sans-serif;
  color: $black;
  font-size: 1.1rem;
  padding: 0;
  letter-spacing: $letter-spacing;
}

h3 {
  color: $grey;
  font-size: 1rem;
}

small,
input,
textarea {
  font-size: 0.85rem;
}

figure {
  margin: 0;
}
