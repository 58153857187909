@import "../../styles/_config.scss";

.header {
  @include flex($ai: center);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  text-align: center;
  color: $grey;
  background-color: $white;
  padding: 1rem;
  margin: 0;

  @media (min-width: $media-min-width) {
    padding: 2rem 1rem;
  }

  & > * {
    flex-basis: 100%;
  }
}

.logo {
  font: {
    size: 1rem;
    weight: 800;
  }

  @media (min-width: $media-min-width) {
    font-size: 1.2rem;
  }

  a {
    text-decoration: none;
    color: $grey;
    transition: $transition-speed;
    letter-spacing: $letter-spacing;

    &:hover,
    &:active {
      filter: brightness(0%);
    }
  }
}
