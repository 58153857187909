@import "../../styles/_config.scss";

.container {
  @include flex($fd: column, $jc: center, $gap: 2rem, $ai: flex-start);
  margin: 0;

  @media (min-width: $media-min-width) {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
}
