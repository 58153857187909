@import "../../styles/_config.scss";

.card {
  @include flex($jc: center, $ai: center);
  @include size($w: 100%, $minh: 14rem);
  text-align: center;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  transition: filter $transition-speed;

  @media (min-width: $media-min-width) {
    height: 100%;
  }

  &:first-of-type {
    background-color: $pastel-pink;
  }
  &:nth-of-type(2) {
    background-color: $pastel-yellow;
  }
  &:nth-of-type(3) {
    background-color: $pastel-blue;
  }

  &:hover {
    filter: brightness(95%);
  }
}

.card_heading {
  color: $black;

  h2 {
    font-family: "Righteous", cursive;
    font-size: 2rem;
    letter-spacing: $letter-spacing;
  }
}
