@import "../../styles/_config.scss";

.input {
  padding: 1rem;
  border: 1px solid $mid-grey;
  border-radius: 8px;
}

.invalid {
  border: 1px solid $pastel-pink;
}

.invalid__icon {
  @include absolute($r: 0);
  height: 100%;
  color: $dark-pink;
  font-size: 1.25rem;
  margin-right: 1rem;
  stroke-width: 0;
}
