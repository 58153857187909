@import "../../../styles/_config.scss";

.navItems {
  ul {
    @include flex($fd: column, $ai: flex-start);
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;

    @media (min-width: $media-min-width) {
      flex-direction: row;
    }
  }
}
