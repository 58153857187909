@import "../../styles/_config.scss";

/* Wrapper for whole select component (minus label) */
.wrapper {
  @include flex($fd: column);
}

.clear {
  @include absolute($t: 0, $r: 5px);
  font-weight: 500;
  opacity: 1;
  color: var(--color);
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

/* Select input with text and arrow */
.select {
  @include flex($jc: space-between, $ai: center);

  min-width: 12rem;
  background-color: $white;
  border: 1px solid $mid-grey;
  border-radius: 4px;
  padding: 0.75rem 1rem;

  cursor: pointer;
  transition: 0.2s;

  &.open,
  &:hover {
    border: 1px solid $grey;
  }

  .arrow {
    border: solid $grey;
    border-width: 0 1.5px 1.5px 0;
    margin-top: -3px;
    padding: 2.5px;

    opacity: 0.7;
    transition: 0.2s ease-in-out;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.open span {
    margin-top: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

/* Dropdown container and list */
.select__options {
  @include absolute($t: 100%);

  background-color: $white;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 4%);

  border-radius: 8px;
  margin-top: 0.75rem;
  z-index: 10;

  ul {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
  }

  li {
    padding: 0.75rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: $light-grey;
    }

    &.active {
      color: var(--color);
      font-weight: 500;
    }
  }
}
