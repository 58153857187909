@import "../../../styles/_config.scss";

.container {
  @include flex($jc: flex-start, $ai: center);
}

.hamburger {
  @include flex($fd: column, $jc: flex-start, $ai: flex-start);
  @include iconBtn($w: 2.5rem);

  &:hover,
  &.active {
    color: var(--color);
  }
}
