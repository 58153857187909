@import "../../styles/_config.scss";

.backdrop {
  position: fixed;
  top: $header-height-mobile;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;

  @media (min-width: $media-min-width) {
    top: $header-height-web;
  }
}
