@import "../../styles/_config.scss";

.category {
  width: 100%;
  @include flex($fd: row, $jc: center, $ai: center, $gap: 2rem);

  & > * {
    flex-basis: 100%;
  }

  svg {
    color: var(--color);
    font-size: 2rem;
  }
}
