/* :before and :after tags */
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin iconBtn($w: 2rem) {
  height: 100%;
  width: $w;
  padding: 0 0.5rem 0 0;
  cursor: pointer;
  transition: $transition-speed;
  user-select: none;
}

/* Display Flex */
@mixin flex(
  $fd: false,
  $jc: false,
  $ai: false,
  $gap: false,
  $fw: false,
  $flex: false
) {
  display: flex;

  @if $fd != false {
    flex-direction: $fd;
  }

  @if $jc != false {
    justify-content: $jc;
  }

  @if $ai != false {
    align-items: $ai;
  }

  @if $fw != false {
    flex-wrap: $fw;
  }

  @if $gap != false {
    gap: $gap;
  }

  @if $flex != false {
    flex: $flex;
  }
}

/* Size */
@mixin size(
  $w: false,
  $maxw: false,
  $minw: false,
  $h: false,
  $maxh: false,
  $minh: false
) {
  @if $w != false {
    width: $w;
  }

  @if $maxw != false {
    max-width: $maxw;
  }

  @if $minw != false {
    min-width: $minw;
  }

  @if $h != false {
    height: $h;
  }

  @if $maxh != false {
    max-height: $maxh;
  }

  @if $minh != false {
    min-height: $minh;
  }
}

/* Position absolute */
@mixin absolute($t: false, $r: false, $b: false, $l: false) {
  position: absolute;

  @if $t != false {
    top: $t;
  }

  @if $r != false {
    right: $r;
  }

  @if $b != false {
    bottom: $b;
  }

  @if $l != false {
    left: $l;
  }
}
