@import "../../styles/_config.scss";

.timer {
  @include flex($fd: row, $jc: center, $ai: center, $gap: 0.5em);
  margin: 0;
  font-size: 1.5rem;

  svg {
    color: var(--color);
    font-size: 2rem;
  }

  p {
    margin: 0;
    width: 2em;
    text-align: center;
    color: $grey;
    transition: $transition-speed;
  }

  .warning {
    color: $dark-pink;
    font-weight: 700;
    font-size: 2rem;
  }
}
