@import "../../styles/_config.scss";

.dropdown {
  @include flex($fd: column, $gap: 1rem);
  width: 100%;
  padding: 2rem 0 0;
  transition: linear 100ms;
  transform-origin: 0% 10%;
  user-select: none;

  @media (min-width: $media-min-width) {
    flex-direction: row;
  }
}

.enter {
  transform: scale(1, 0);
}

.enterActive {
  transform: scale(1, 1);
}

.exit {
  transform: scale(1, 1);
}

.exitActive {
  transform: scale(1, 0);
}
