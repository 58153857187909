@import "../../styles/_config.scss";

.button {
  position: relative;
  border: none;
  border-radius: 8px;
  color: $white;
  padding: 1rem 2rem;
  font-weight: 700;
  font-size: 0.8rem;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;
  width: fit-content;
  cursor: pointer;
  transition: box-shadow $transition-speed linear;
  background-color: var(--color);
  border: 1px solid var(--color);
  box-shadow: -2px 2px 0 rgb(0 0 0 / 20%);

  &:hover,
  &:active {
    box-shadow: -4px 4px 0 rgb(0 0 0 / 20%);
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    box-shadow: -2px 2px 0 rgb(0 0 0 / 20%);
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.invert {
  background-color: $white;
  color: var(--color);
}

.large {
  margin: 2rem auto;
  padding: 1.5rem 3rem;
  font-size: 1.2rem;
  box-shadow: -8px 8px 0px -2px rgba(0, 0, 0, 0.7);

  &:hover,
  &:active {
    box-shadow: -10px 10px 0px -2px rgba(0, 0, 0, 0.7);
  }
}

.small {
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
}

.fullWidth {
  width: 100%;
}
